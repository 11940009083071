import * as Cookies from "js-cookie";
import { ACCESS_TOKEN, REFRESH_TOKEN, RETURN_URL, REDIRECTED } from "./variables";

const redirect = (url) => {
  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(REFRESH_TOKEN);
  window.location.href = `${url}&${RETURN_URL}=${encodeURIComponent(window.location.href)}&${REDIRECTED}=true`;
};

export default redirect;
