import { confirmAlert } from "react-confirm-alert";
import templates from "./staticTemplates";

import "react-confirm-alert/src/react-confirm-alert.css";
import "./TemplatesPanel.css";

const getCard = (editor, { title, content, preview }) => {
  const card = document.createElement("button");
  card.className = "card";
  card.title = `Select template "${title}"`;
  card.innerHTML = `<img src="${preview}" alt="${title}" /><div>${title}</div>`;
  card.onclick = () => {
    confirmAlert({
      title: "Confirmation",
      message:
        "Are you sure you want to use this template? The email you are currently working on will be lost and replaced by this template.",
      buttons: [
        {
          label: "Yes",
          onClick: () => editor.setComponents(content),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const previewButton = document.createElement("button");
  previewButton.className = "fa fa-search";
  previewButton.title = `View template "${title}"`;
  previewButton.onclick = (e) => {
    e.stopPropagation();

    const backToBuilderButton = `<button class="fa fa-search" onclick="window.close();" style="
      position: fixed;
      top: 16px;
      left: 16px;
      background-color: rgb(2,152,214);
      border-radius: 12px;
      border: none;
      height: 2rem;
      width: 15rem;
      color:#FFF;
      cursor:pointer;
    ">Back to builder</button>`;

    // open template preview with back button permitting to return to builder
    window
      .open("url", "_blank", "toolbar=0,location=0,menubar=0, scrollbars=1")
      .document.write(`${content}${backToBuilderButton}`);
  };

  card.appendChild(previewButton);

  return card;
};

const getCards = (editor, templates) => {
  const cards = document.createElement("div");
  cards.id = "templates-cards-container";

  templates.forEach((template) => cards.appendChild(getCard(editor, template)));

  return cards;
};

const TemplatesPanel = (editor) => {
  const panel = document.createElement("div");
  panel.id = "templates-panel";

  const title = document.createElement("h2");
  title.innerHTML = "Templates";
  panel.appendChild(title);

  const cards = getCards(editor, templates);
  panel.appendChild(cards);

  return panel;
};

export default TemplatesPanel;
