import React from "react";
import { callMindbazApi } from "../services/http/callApi";

export const useMindbazPicture = (idSite, idCampaign, shouldExecute = true) => {
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState();
  const [idDirectory, setIdDirectory] = React.useState();

  React.useEffect(() => {
    if (idSite && idCampaign && shouldExecute) {
      setLoading(true);
      // ? 3 is for image https://preprod-api.mindbaz.com/Help/fr/ResourceModel?modelName=EDirType
      callMindbazApi(`${idSite}/directories/3/all`, {
        method: "GET",
      })
        .then(function (result) {
          const pictures = result.data.data;

          if (pictures) {
            const mapedData = pictures.filter((item) => item.name === "camp_" + idCampaign);

            if (mapedData.length >= 1) {
              setIdDirectory(mapedData[0].id);
            }
          }
        })
        .catch(function (error) {
          //Handle error
          setLoading(false);
        });
    }
  }, [idSite, idCampaign, shouldExecute]);

  React.useEffect(() => {
    if (idDirectory) {
      callMindbazApi(`${idSite}/pictures?Location=${idDirectory}`, {
        method: "GET",
      })
        .then(function (result) {
          const pictures = result.data.data.items;

          if (pictures) {
            const mapedData = pictures
              .map(({ url: src, ...rest }) => ({
                src,
                ...rest,
              }))
              .filter((item) => item.name !== "UnitTest");

            if (mapedData.length >= 1) {
              setValue(mapedData);
            }
          }
          setLoading(false);
        })
        .catch(function (error) {
          //Handle error
          setLoading(false);
        });
    }
  }, [idDirectory, idSite]);

  return { loading, value };
};
