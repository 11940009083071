import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup, Button } from "@mindbaz/ui";
import styled from "styled-components";

import logo from "../../assets/mindbaz_logo.svg";
import { ButtonsContainer, ContainerLogo, Logo, TopBarButton, Wrapper } from "./TopBar.styles";
import { InfoCtx } from "../../App";

const StyledFooter = styled("div")({
  display: "flex",
  justifyContent: "center",
});

function TopBar({ handleSave, editor, defaultHtml }) {
  const { t } = useTranslation();
  const { idSite, idCampaign, siteOrigin } = React.useContext(InfoCtx);
  const [isQuitPopupOpen, setIsQuitPopupOpen] = useState(false);

  const changeSiteUrl = React.useMemo(
    () => (idSite ? `${process.env.REACT_APP_MINDBAZ_URL}ChangeSite.aspx?id=${idSite}&successUrl=` : ""),
    [idSite]
  );

  const redirUrl = React.useMemo(
    () => `${process.env.REACT_APP_MINDBAZ_URL}campaigns/manage_campaign_html_code.aspx?campaignId=${idCampaign}`,
    [idCampaign]
  );

  const handleReturn = useCallback(() => {
    window.onbeforeunload = null;
    const currentHtml = editor.runCommand("gjs-get-inlined-html");
    if (currentHtml !== defaultHtml) {
      setIsQuitPopupOpen(true);
    } else {
      window.location.replace(siteOrigin !== "null" ? siteOrigin : `${changeSiteUrl}${redirUrl}`);
    }
  }, [siteOrigin, changeSiteUrl, redirUrl, defaultHtml, editor]);

  const handleQuit = useCallback(() => {
    window.location.replace(siteOrigin !== "null" ? siteOrigin : `${changeSiteUrl}${redirUrl}`);
  }, [siteOrigin, changeSiteUrl, redirUrl]);

  const handleClose = useCallback(() => {
    setIsQuitPopupOpen(false);
  }, [setIsQuitPopupOpen]);

  return (
    <Wrapper style={{ display: "flex", justifyContent: "space-between" }}>
      <ContainerLogo>
        <Logo src={logo} />
      </ContainerLogo>
      <ButtonsContainer>
        <TopBarButton onClick={handleReturn}>{t("button.quit")}</TopBarButton>
        <TopBarButton onClick={() => handleSave(true)}>{t("button.save_and_quit")}</TopBarButton>
        <TopBarButton onClick={() => handleSave(false)}>{t(t("button.save"))}</TopBarButton>
      </ButtonsContainer>
      <Popup
        description={t("modal.quit_without_save")}
        open={isQuitPopupOpen}
        onClose={handleClose}
        footer={() => (
          <StyledFooter>
            <Button status="success" onClick={handleQuit}>
              {t("button.quit")}
            </Button>
            <Button status="cancel" onClick={handleClose}>
              {t("button.cancel")}
            </Button>
          </StyledFooter>
        )}
      />
    </Wrapper>
  );
}

export default TopBar;
