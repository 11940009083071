import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import FR from "./locales/fr";
import EN from "./locales/en";
import ES from "./locales/es";

const resources = {
  FR: { translation: FR },
  EN: { translation: EN },
  ES: { translation: ES },
};

const lng = new URLSearchParams(window.location.search).get("lang");

i18n.use(initReactI18next).init({
  whitelist: Object.keys(resources),
  fallbackLng: ["FR"],
  resources,
  lng,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
