import axios from "axios";
import Cookies from "js-cookie";

function createCall(baseUrl) {
  if (!baseUrl) {
    throw new Error("No base URL was specified.");
  }

  return function (endpoint, rest) {
    const token = Cookies.get("at");

    return axios({
      url: `${baseUrl}${endpoint}`,
      ...rest,
      ...(token ? { headers: { Authorization: `Bearer ${token}`, ...rest.headers } } : {}),
    });
  };
}

export const callMindbazApi = createCall(process.env.REACT_APP_API_MINDBAZ);
