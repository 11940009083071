import axios from "axios";
import refreshToken from "./refreshToken";

let isRefreshing = false;
let refreshSubscribers = [];

const interceptor = ({ endpoint, appId, onRefreshTokenFailed }) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;

      // retry request once if token error
      if (status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;

          refreshToken(endpoint, appId)
            .then((token) => {
              isRefreshing = false;
              // retry requests stacked with refreshed token
              refreshSubscribers.forEach((subscriber) => subscriber(token));
            })
            .catch(() => {
              onRefreshTokenFailed();
            });
        }

        // stack requests to retry after token is refreshed
        return new Promise((resolve) => {
          refreshSubscribers.push((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            resolve(axios(originalRequest));
          });
        });
      }
    }
  );
};

export default interceptor;
