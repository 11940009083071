import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./translations";
import * as serviceWorker from "./serviceWorker";
import TokenProvider from "./services/http/TokenProvider";

import "./index.css";

const { REACT_APP_LOGIN_URL, REACT_APP_APPLICATION_ID, REACT_APP_SSO_URL } = process.env;

ReactDOM.render(
  <TokenProvider
    endpoint={REACT_APP_SSO_URL}
    appId={REACT_APP_APPLICATION_ID}
    redirectUrl={`${REACT_APP_LOGIN_URL}Account/Login?brand_id=${REACT_APP_APPLICATION_ID}`}
  >
    <App />
  </TokenProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
