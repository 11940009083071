import styled from "styled-components";

export const ContainerLogo = styled.div`
  background-color: #444;
  height: 66px;
  width: 320px;
  overflow: hidden;
  float: left;
`;

export const Logo = styled.img`
  height: 100%;
  width: 100%;
  transform: scale(3.6);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 4rem;
`;

export const ButtonsContainer = styled.div`
  background-color: #444;
  width: 100%;
`;

export const TopBarButton = styled.button`
  background-color: white;
  border-radius: 12px;
  height: 2rem;
  width: 15rem;
  float: right;
  font-size: 0.875rem;
  color: rgb(2, 152, 214);
  border: 1px rgb(2, 152, 214);
  margin: 1rem 1rem;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  font-family: "Maison Neue", "Open Sans", "Helvetica Neue", helvetica, arial, verdana, sans-serif;
`;
