import * as React from "react";
import Tour from "reactour";
import * as Cookies from "js-cookie";

const Tutorial = ({ steps, label, opened, onClose }) => {
  const [isOpen, open] = React.useState(opened);

  const cookieName = React.useMemo(() => "tuto", []);
  const cookie = Cookies.get(cookieName);
  const COOKIE_EXPIRE_TIME = new Date(new Date().getTime() + 3600 * 1000 * 24 * 365);

  React.useEffect(() => {
    if ((!cookie || !cookie?.includes(label)) && !isOpen) open(true);
  }, [isOpen, label, cookie]);

  const onCloseHandle = React.useCallback(() => {
    if (onClose) onClose();

    if (!cookie) {
      Cookies.set(cookieName, JSON.stringify([label]), {
        expires: COOKIE_EXPIRE_TIME,
      });
    } else if (!cookie?.includes(label)) {
      Cookies.set(cookieName, [...JSON.parse(cookie), label], {
        expires: COOKIE_EXPIRE_TIME,
      });
    }

    open(false);
  }, [label, onClose, COOKIE_EXPIRE_TIME, cookie, cookieName]);

  return <Tour startAt={0} steps={steps} isOpen={isOpen} onRequestClose={onCloseHandle} />;
};

export default Tutorial;
