import React, { useCallback, useState } from "react";
import Cookies from "js-cookie";
import GrapesJS from "grapesjs";
import newsletter from "grapesjs-preset-newsletter";
import customCode from "grapesjs-custom-code";
import { Alert } from "@mindbaz/ui";
import { useTranslation } from "react-i18next";

import TopBar from "../TopBar/TopBar";
import Loader from "../Loader";
import { InfoCtx } from "../../App";
import { useMindbazPicture } from "../../hooks/useFetchPictures";
import { initializeGrape } from "./editorInitialize";
import { callMindbazApi } from "../../services/http/callApi";
import Tutorial from "../Tutorial/Tutorial";
import firstTimeSteps from "../Tutorial/steps/firstTime";
import beginnerSteps from "../Tutorial/steps/beginner";

import { TokenContext } from "../../services/http/TokenProvider";

import "ckeditor";
import ckEditorPlugin from "grapesjs-plugin-ckeditor";

import "grapesjs/dist/css/grapes.min.css";
import fr from "grapesjs/src/i18n/locale/fr";
import es from "grapesjs/src/i18n/locale/es";
import en from "grapesjs/src/i18n/locale/en";

const defaultStyle = `  
  table {
    max-width: 600px;
    margin: auto!important;
  }
  
  td table {
    max-width: inherit;
  }
  
  .button {
    background-color: #d61d7d;
    color: #FFF;
    padding: 10px 20px;
    cursor: pointer;
  }
`;

const LandingCampaign = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title></title>
  </head>
  <body>
  </body>
</html>`;

function Editor({ campaignData, isCampaignLoading }) {
  const { t } = useTranslation();
  const { idSite, idCampaign, siteOrigin } = React.useContext(InfoCtx);
  const [editor, setEditor] = React.useState(undefined);
  const [isLoading, setLoading] = React.useState(true);
  const [hasNeedBeginnerTutorial, callBeginnerTutorial] = React.useState(false);
  const [headerText, setHeaderText] = React.useState("");
  const [defaultHtml, setDefaultHtml] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const { loading: pictureLoading, value: pictures } = useMindbazPicture(idSite, idCampaign, campaignData === undefined);

  const tokenContext = React.useContext(TokenContext);

  /**
   * Initialize
   */

  React.useEffect(() => {
    const token = Cookies.get("at");

    const grape = GrapesJS.init({
      assetManager: {
        openAssetsOnDrop: 1,
        upload: `${process.env.REACT_APP_API_MINDBAZ}${idSite}/pictures/frombuilder?idCampaign=${idCampaign}`,
        autoAdd: false,
        uploadName: "picture",
        credentials: "omit",
        headers: { Authorization: `Bearer ${token}` },
        showUrlInput: true,
      },
      protectedCss: defaultStyle,
      blocks: [],
      blockManager: {},
      container: "#gps",
      components: LandingCampaign,
      height: "auto",
      plugins: [newsletter, customCode, ckEditorPlugin],
      pluginsOpts: {
        [ckEditorPlugin]: {
          position: "center",
          options: {
            language: "en",
            toolbar: [
              { name: "styles", items: ["Font", "FontSize"] },
              ["Bold", "Italic", "Underline", "Strike"],
              { name: "links", items: ["Link", "Unlink"] },
              { name: "colors", items: ["TextColor", "BGColor"] },
            ],
          },
        },
      },
      storageManager: { type: null },
      width: "auto",
      i18n: {
        locale: "en", // default locale
        detectLocale: true, // by default, the editor will detect the language
        messages: { fr, es, en },
      },
    });

    initializeGrape(grape, idCampaign, idSite, setLoading, callBeginnerTutorial, tokenContext);

    setEditor(grape);
    setLoading(false);
    return () => {
      if (editor) editor.destroy();
    };
    //eslint-disable-next-line
  }, []);

  /**
   * Add effects
   */

  React.useEffect(() => {
    // save existing header
    if (
      campaignData &&
      campaignData.htmlContent &&
      (campaignData.htmlContent.toLowerCase().startsWith("<!doctype") ||
        campaignData.htmlContent.toLowerCase().startsWith("<html"))
    ) {
      const header = campaignData.htmlContent.substr(0, campaignData.htmlContent.toLowerCase().indexOf("<body"));
      if (!header.toLowerCase().includes("<!doctype")) {
        setHeaderText(
          `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n${header}<body>`
        );
      } else {
        setHeaderText(`${header}<body>`);
      }
    }

    if (
      campaignData &&
      campaignData.htmlContent &&
      !(
        campaignData.htmlContent.toLowerCase().startsWith("<!doctype") ||
        campaignData.htmlContent.toLowerCase().startsWith("<html")
      )
    ) {
      setHeaderText(
        `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html><body>`
      );
    }

    if (editor && !isLoading && !isCampaignLoading) {
      editor.Panels.getButton("views", "templates").set("active", true);

      // if campaign html is different to default html
      if (campaignData && campaignData.htmlContent?.replace(/\s/g, "") !== LandingCampaign.replace(/\s/g, "")) {
        editor.setComponents(campaignData.htmlContent);
        editor.Panels.getButton("views", "open-blocks").set("active", true);
      }
      setDefaultHtml(editor.runCommand("gjs-get-inlined-html"));
    }
  }, [isCampaignLoading, editor, campaignData, isLoading]);

  React.useEffect(() => {
    if (!pictureLoading && editor && pictures) {
      editor.AssetManager.add(pictures);
    }
  }, [pictureLoading, editor, pictures]);

  /**
   * Save
   * @param quit - boolean - if true, exit builder.
   */
  const handleSave = (quit) => {
    const changeSiteUrl = idSite ? `${process.env.REACT_APP_MINDBAZ_URL}ChangeSite.aspx?id=${idSite}&successUrl=` : "";
    const redirUrl = `${process.env.REACT_APP_MINDBAZ_URL}campaigns/manage_campaign_html_code.aspx?campaignId=${idCampaign}`;

    if (editor) {
      // get generated html by builder and concat it with existing header.
      const savedHtml = editor.runCommand("gjs-get-inlined-html");
      setDefaultHtml(savedHtml);
      let htmlWithoutGrappedJSHeader = savedHtml.includes("<table")
        ? savedHtml.substring(savedHtml.indexOf("<table"))
        : savedHtml;
      htmlWithoutGrappedJSHeader = htmlWithoutGrappedJSHeader.replace(/<meta([^>]+)>/g, "");
      htmlWithoutGrappedJSHeader = htmlWithoutGrappedJSHeader.replace(/<title[^>]*>([^]*)<\/title>/, "");
      const htmlContent = headerText.concat(htmlWithoutGrappedJSHeader, `\n</body>\n</html>`);

      // grapes.js command which permit to get html and css
      const data = { ...campaignData, htmlContent: htmlContent };

      callMindbazApi(`${idSite}/campaigns`, {
        method: "PUT",
        data: data,
      })
        .then(() => {
          if (quit) {
            window.onbeforeunload = null;
            window.location.assign(siteOrigin !== "null" ? siteOrigin : `${changeSiteUrl}${redirUrl}`);
          } else {
            setIsOpen(true);
          }
        })
        .catch(function (error) {
          window.alert("Impossible sauvegarder la campagne du builder");
        });
    } else {
      window.alert("Impossible de récupérer la campagne du builder");
    }
  };

  const tutorialLabel = hasNeedBeginnerTutorial ? "ft" : "bg";

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <React.Fragment>
      {isLoading ? <Loader /> : null}
      <TopBar handleSave={handleSave} editor={editor} defaultHtml={defaultHtml} />
      <div id="gps" style={{ flex: "1 1 auto" }} />
      <Tutorial
        key={tutorialLabel}
        steps={hasNeedBeginnerTutorial ? beginnerSteps : [...beginnerSteps, ...firstTimeSteps]}
        label={tutorialLabel}
        opened={hasNeedBeginnerTutorial}
        onClose={() => callBeginnerTutorial(false)}
      />
      <Alert open={isOpen} onClose={handleClose} severity="success">
        {t("modal.saveConfirmation")}
      </Alert>
    </React.Fragment>
  );
}

export default Editor;
